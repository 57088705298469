import loadable from '@loadable/component';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home/home';
import Loading from './pages/loading/loading';
const NotFoundPage = loadable(() => import('./pages/not-found/not-found-page'));
// const Home = loadable(() => import('./pages/home/home'));
const Cgu = loadable(() => import('./pages/cgu/cgu'));
const Email = loadable(() => import('./pages/email/email'));
const Testing = loadable(() => import('./pages/testing/testing'));

export default function RoutesPrintYourFriends() {
  return (
    <div className={`main-content`}>
      <BrowserRouter>
        <Routes>
          <Route path="/event/:event" element={<Home />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/cgu" element={<Cgu />} />
          <Route path="/email" element={<Email />} />
          <Route path="/testing" element={<Testing />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
