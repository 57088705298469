/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import { useSpring } from '@react-spring/web';
import html2canvas from 'html2canvas';
import React, { useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import OffCanvas from '../off-canvas/off-canvas';
import QuantityModal from '../quantity/quantity';
import Camera from './camera';
import './photos.scss';
var platform = require('platform');
const buggingDevices = ['SM-G980F', 'SM-S908B'];

const Wrapper = styled.div`
  height: 360px;
  margin: 0 auto;

  @media (min-width: 390px) {
    height: 390px;
  }

  @media (min-width: 400px) {
    width: 350px;
  }
`;

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;
  &:hover {
    opacity: 0.7;
  }
`;

const UploadGalleryDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  cursor: pointer;

  & > img {
    width: 60px;
    height: 60px;
  }
`;

const TakePhotoButton = styled(Button)`
  filter: none;
  text-shadow: none;
  width: 70px;
  height: 70px;
  background: url('/assets/images/capture.svg') no-repeat 0 0;
  background-size: 100%;
`;

const ChangeFacingCameraButton = styled(Button)`
  filter: none;
  text-shadow: none;
  width: 40px;
  height: 42px;
  background: url('/assets/images/rotate.svg') no-repeat 0 0;
  // position: absolute;
  // right: 32px;
  // top: 13px;
`;

const Control = styled.div`
  width: 100%;
  padding: 0 22px 0 22px;
  margin-bottom: 50px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media (min-width: 992px) {
    padding: 0 32px 40px 22px;
  }
`;

function Photos({
  cadrePhotos,
  folderId,
  idEvent,
  isSaveGalerie,
  dossier_galerie_id_google_drive,
  onSuccess,
  loading,
  homeRef,
  nombre_exemplaires,
  nombre_exemplaires_reste,
  nombre_maximum_tirage,
  current_tirage_number,
  loadingSimulate,
}) {
  const [loadingSim, setLoading] = useState(false);
  const [activeDeviceId, setActiveDeviceId] = useState(undefined);
  const [devices, setDevices] = useState(undefined);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [classNameImage, setClassNameImage] = useState('');
  const [cameraHeight, setCameraHeight] = useState(0);
  const camera = useRef();
  const [openModal, setOpenModal] = useState(false);
  const exportRef = React.useRef();
  const newCanvasRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState(null);
  const [sucess, setSuccess] = useState(null);
  const imageRef = useRef();
  const [showImage, setShowImage] = useState(true);
  const [{ x, y, scale }, set] = useSpring(() => ({ x: 0, y: 0, scale: 1 }));
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];

        const img = new Image();
        img.src = `data:image/jpeg;base64,${base64String}`;

        img.onload = () => {
          const isPortrait = img.width < img.height;
          setSelectedFile(`data:image/jpeg;base64,${base64String}`);
          setClassNameImage(isPortrait ? 'portrait' : 'landscape');
        };
      };

      reader.readAsDataURL(file);
    }
  };
  const [isMoveable, setIsMoveable] = React.useState(true);

  const onDrag = () => {
    setIsMoveable(true);
    //etc
  };
  const onStop = () => {
    setIsMoveable(false);
    //etc
  };

  const inputFile = useRef(null);

  const handleResize = () => {
    console.log('handle resize');
    const { scrollHeight, clientHeight, clientWidth } = homeRef.current;
    console.log(clientWidth);
    if (clientWidth > 600) {
      // console.log('height is scrolled, should calcul width from height')
      setContainerHeight(clientHeight - 200);
      setCameraHeight(clientHeight - 420); // marge 220
      setContainerWidth((clientHeight - 200) / 1.5);
      // setMarge(220)
    } else if (600 > clientWidth > 500) {
      setContainerWidth(clientWidth - 100);
      setContainerHeight((clientWidth - 100) * 1.5);
      setCameraHeight((clientWidth - 100) * 1.5 - 300); // marge 300
    } else if (500 > clientWidth > 400) {
      setContainerWidth(clientWidth - 100);
      setContainerHeight(-100 * 1.5);
      setCameraHeight(-100 * 1.5 - 250);
    } else {
      console.log(
        platform.name,
        platform.manufacturer,
        platform.product,
        platform.version,
        platform.os
      );
      console.log(platform.description);

      setContainerWidth(clientWidth - 50);
      setContainerHeight((clientWidth - 50) * 1.5);
      setCameraHeight((clientWidth - 50) * 1.5 - 130);
      if (
        platform.os?.family === 'Android' &&
        clientWidth === 360 &&
        buggingDevices.includes(platform.product)
      ) {
        console.log('its samsung s22 or s22', platform.product);
        setCameraHeight(500);
      }
    }
  };
  React.useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind == 'videoinput');
      setDevices(videoDevices);
      // setActiveDeviceId(videoDevices[0].deviceId);
    })();

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    if (selectedFile) {
      const timeout = setTimeout(() => {
        setShowImage(false);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [selectedFile]);

  const exportAsImage = async (element, imageFileName, quantity) => {
    console.log('element', element.offsetWidth);
    try {
      const canvas = await html2canvas(element, {
        useCORS: true,
        background: null,
        logging: true,
        removeContainer: true,
      });

      const image = canvas.toDataURL('image/jpeg', 1);
      //  downloadImage(image, imageFileName);
      // send image drive

      const payload = {
        fileName: imageFileName + '.jpeg',
        isSaveGalerie: isSaveGalerie,
        dossier_galerie_id_google_drive: dossier_galerie_id_google_drive,
        folder_id: folderId,
        idEvent: idEvent,
        print: image,
        quantity: quantity,
      };

      setSuccess(true);
      console.log('exportAsImage fired!');
      if (onSuccess)
        onSuccess({
          image,
          payload,
        });
    } catch (e) {
      console.error(e);
    }
  };

  const showPrint = () => {
    return image || selectedFile;
  };

  const showUpload = () => {
    return !image && !selectedFile;
  };

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement('a');
    fakeLink.style = 'display:none;';
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  const launchPrint = (quantity) => {
    exportAsImage(exportRef.current, `${uuidv4()}-${Date.now()}`, quantity);
  };

  const showLoadingCamera = () => {
    return loadingSim || loadingSimulate;
  };

  return (
    <>
      {openModal && (
        <OffCanvas
          isOpen={openModal}
          props={{
            placement: 'bottom',
            backdrop: false,
          }}
          content={
            <QuantityModal
              title="Quelle quantité de photo souhaitez-vous imprimer ?"
              tirage={
                nombre_maximum_tirage - parseInt(current_tirage_number) <
                nombre_exemplaires
                  ? `Le nombre de tirages est limité à ${nombre_exemplaires} impressions par personne. Il reste seulement ${
                      nombre_maximum_tirage - parseInt(current_tirage_number)
                    } tirage${
                      nombre_maximum_tirage - parseInt(current_tirage_number) >
                      1
                        ? 's'
                        : ''
                    } pour cet évènement. `
                  : `Le nombre de tirages est limité à ${nombre_exemplaires} impressions par personne.`
              }
              maxQuantity={
                nombre_maximum_tirage - parseInt(current_tirage_number) >=
                nombre_exemplaires
                  ? nombre_exemplaires
                  : nombre_maximum_tirage - parseInt(current_tirage_number)
              }
              launchPrint={launchPrint}
            />
          }
        />
      )}
      <div className="row">
        <div className="col">
          <div className="home-camera__wrap">
            {' '}
            <div
              ref={exportRef}
              className="export"
              style={{
                width: containerWidth,
                height: containerHeight,
                margin: '0 auto',
                background: 'transparent',
              }}
            >
              <div
                className="home-camera__frame"
                style={
                  {
                    // width: containerWidth,
                    // height: containerHeight,
                  }
                }
              >
                <img
                  src={cadrePhotos}
                  style={{
                    width: containerWidth,
                    height: containerHeight,
                  }}
                />
              </div>
              <Wrapper className="wrap-photo">
                {showLoadingCamera() && (
                  <div className="loading__container camera-loader">
                    <img src="/assets/images/loading.gif" width="60" />
                  </div>
                )}
                {!image && !selectedFile ? (
                  <>
                    <Camera
                      ref={camera}
                      errorMessages={{
                        noCameraAccessible:
                          'Aucun appareil photo accessible. Veuillez connecter votre caméra ou essayer un autre navigateur.',
                        permissionDenied:
                          'Permission refusée. Veuillez actualiser et autoriser la caméra.',
                        switchCamera:
                          "Il n'est pas possible de changer de caméra car il n'y a qu'un seul appareil vidéo accessible.",
                        canvas: "Le canevas n'est pas pris en charge.",
                      }}
                    />

                    {/*<CustomCamera
                  handlePhoto={(photo) => {
                    setImage(photo)
                  }}
                  innerRef={camera}
                />*/}
                  </>
                ) : (
                  <>
                    {image ? (
                      <img src={image} className="picture-taken" />
                    ) : (
                      <>
                        {showImage && (
                          <div className="animation">
                            <img src="/assets/images/animation.gif" />
                          </div>
                        )}
                        <TransformWrapper>
                          <TransformComponent>
                            <img
                              src={selectedFile}

                              className={`picture-upload ${classNameImage}`}
                              
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </>
                    )}
                  </>
                )}
              </Wrapper>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div>
          <div className="col  mt-3 d-flex justify-content-center align-items-center">
            <Control className="capture-control">
              {showUpload() && (
                <>
                  <UploadGalleryDiv className="upload-img">
                    <label>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        ref={inputFile}
                      />
                      <img
                        src="/assets/images/add_photo.svg"
                        alt="Upload Gallery"
                      />
                    </label>
                  </UploadGalleryDiv>
                  <TakePhotoButton
                    className="capture"
                    onClick={() => {
                      if (camera.current) {
                        setImage(camera.current.takePhoto());
                      }
                    }}
                  />
                  <ChangeFacingCameraButton
                    className="rotate"
                    onClick={() => {
                      if (camera.current) {
                        camera.current.switchCamera();
                        setLoading(true);
                        setTimeout(() => {
                          setLoading(false);
                        }, 2500);
                      }
                    }}
                  />
                </>
              )}

              {showPrint() && (
                // {image && !sucess && ( #v1
                <>
                  <div className="print">
                    <button
                      disabled={loading}
                      className="btn btn-white"
                      onClick={() => {
                        if (!openModal) {
                          setOpenModal(true);
                        } else {
                          exportAsImage(
                            exportRef.current,
                            `${uuidv4()}-${Date.now()}`,
                            1
                          );
                        }
                      }}
                    >
                      {' '}
                      Top, j'imprime ! 🔥
                    </button>
                    <button
                      disabled={loading}
                      className="btn btn-retake"
                      onClick={() => {
                        setImage(null);
                        setSelectedFile(null);
                        setClassNameImage('');
                        setLoading(true);
                        setShowImage(true);
                        setTimeout(() => {
                          setLoading(false);
                        }, 2500);
                      }}
                    >
                      {' '}
                      Reprendre{' '}
                    </button>
                  </div>
                </>
              )}
            </Control>
          </div>
        </div>
      </div>

      <canvas ref={newCanvasRef}></canvas>
    </>
  );
}

export default Photos;
