import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/globalState';

function LoadingModal({ title, disabled, onClickEmail, resend, isError }) {
  const {
    info_events: {
      nombre_maximum_tirage,
      message_chargement,
      slug,
      current_tirage_number,
    },
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const rest =
    parseInt(nombre_maximum_tirage, 10) - parseInt(current_tirage_number);

  const resendImage = () => {
    resend();
  };
  const email = () => {
    if (onClickEmail) onClickEmail();
    navigate('/email', { replace: true, state: { slug } });
  };
  return (
    <div>
      <div className="photo__receive">
        <p className="photo__text">
          Si le problème persiste, n'hésitez pas à nous contacter pour obtenir
          de l'aide.
        </p>
        {/*  <button className="btn btn-pink"  disabled={disabled} onClick={email}>Reçois ta photo par email ✉️</button> */}
        {isError && (
          <button className="btn btn-pink" onClick={resendImage}>
            Réessayer l'envoi de photo
          </button>
        )}
        {/*<div className="logo">
          <img
            alt="Print your friends"
            src="/assets/images/logo-white.png"
            width={100}
            height={100}
          />
  </div>*/}

        {/*     <a
          href="http://printyourfriends.com/"
          target="_blank"
          rel="noreferrer"
          className="btn btn-loue mb-2 mt-4"
        >
          Toi aussi, loue ton imprimante
</a>*/}
      </div>
    </div>
  );
}

export default LoadingModal;
