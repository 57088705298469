import React, { useContext } from 'react';
import { GlobalContext } from '../../context/globalState';
import './layout.scss';
const Layout = ({ children, isShowLogo }) => {
  const { info_events } = useContext(GlobalContext);
  return (
    <div className="impression">
      <div className="container">
        <div className="impression__wrap">
          {isShowLogo && info_events?.logo_client && (
            <div className="logo__free">
              <img
                alt="Freenow"
                src={info_events?.logo_client}
                width={304}
                height={54}
              />
            </div>
          )}
          <>{children}</>
        </div>
      </div>
    </div>
  );
};

export default Layout;