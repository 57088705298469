import React, { createContext, useReducer } from 'react';

import appReducer from './app-reducers';

const initialState = {
  info_events: {},
  email: '',
  base64Images: '',
  quantity: 1,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  function restartEvent() {
    dispatch({
      type: 'RESTART_DATA',
    });
  }

  function setDataEvents(payload) {
    dispatch({
      type: 'SET_DATA_EVENEMENTS',
      payload: payload,
    });
  }

  function setEmailUser(payload) {
    dispatch({
      type: 'SET_EMAIL_USER',
      payload: payload,
    });
  }

  function setBase64Image(payload) {
    dispatch({
      type: 'SET_BASE_IMAGES',
      payload: payload,
    });
  }

  function setQuantity(payload) {
    dispatch({
      type: 'SET_QUANTITY',
      payload: payload,
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        info_events: state.info_events,
        email: state.email,
        base64Images: state.base64Images,
        quantity: state.quantity,
        restartEvent,
        setDataEvents,
        setEmailUser,
        setBase64Image,
        setQuantity,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
