import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/globalState';

function ConfirmationModal({ title, disabled }) {
  const {
    info_events: { slug, url_dossier_galerie, isSaveGalerie },
    setQuantity,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const redo = () => {
    navigate('/event/' + slug, { replace: true });
    setQuantity(1);
  };
  return (
    <div className="photo__bottom">
      <div className="photo__receive">
        {/*      <p className="photo__text">{title || ''}</p>*/}
        <button className="btn btn-pink" disabled={disabled} onClick={redo}>
          Reprendre une photo 📸
        </button>
        {/*<div className="logo">
          <img
            alt="Print your friends"
            src="/assets/images/logo-white.png"
            width={100}
            height={100}
          />
        </div>*/}
        {isSaveGalerie && (
          <a
            href={url_dossier_galerie}
            target="_blank"
            rel="noreferrer"
            className="btn btn-loue mb-2 mt-4"
          >
            Retrouve toutes les photos
          </a>
        )}
      </div>
    </div>
  );
}

export default ConfirmationModal;
