import React, { useContext, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../context/globalState';
import './print-modal.scss';

function PrintModal({onSuccess, loading}) {

  const {info_events: {
    nombre_maximum_tirage,
    message_chargement,
    current_tirage_number
  }} = useContext(GlobalContext);
  const [max, setMax] = React.useState(10)
  const [counter, setCounter] = React.useState(1)
  useEffect(() => {
    if (nombre_maximum_tirage && current_tirage_number) {
      const rest = parseInt(nombre_maximum_tirage, 10) - parseInt(current_tirage_number);
      console.log('rest', rest, nombre_maximum_tirage, current_tirage_number)
      if (rest) {
        setMax(rest);
      }
    }
  }, [current_tirage_number, nombre_maximum_tirage])
  const increment = () => {
    if (max === counter) {
      return;
    }
    // setCounter((c) => c+1)
  }
  const decrement = () => {
    if (counter === 0) {
      return;
    }
    // setCounter(counter-1)
  }
  return (
    <div>
      <div className="form_input_container">
          <div className="form_input_number">
            <Form>
              <InputGroup className="input_group_number"> 
                <InputGroup.Text id="basic-addon1" className={`${counter === 0 ? 'disabled' : ''} cmd decrement disabled`} onClick={decrement}>-</InputGroup.Text>
                <Form.Control type="number" readOnly max={max} min={1} placeholder="0" value={counter} className="input__number" />
                <InputGroup.Text id="basic-addon2" className={`${counter === max ? 'disabled' : ''} cmd increment disabled`} onClick={increment}>+</InputGroup.Text>
              </InputGroup>
            </Form>
          </div>
       <div className="sub mb-3">
       Le nombre de tirages est limité à 10 impressions par personne.
       </div>
        <div className="impr__confirmation">
           <button type="submit" className="btn btn-pink" disabled={loading} onClick={onSuccess}>Lancer l'impression</button>
        </div>
      </div>
    </div>
  );
}

export default PrintModal;
