import LogRocket from 'logrocket';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalProvider } from './context/globalState';
import RoutesPrintYourFriends from './routes';
import './styles/index.scss';
const platform = require('platform');
const md5 = require('md5');

function App() {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const confirmationMessage = 'You have unsaved changes. Continue?';

  const useConfirmTabClose = (isUnsafeTabClose) => {
    React.useEffect(() => {
      const handleBeforeUnload = (event) => {
        if (isUnsafeTabClose) {
          event.returnValue = confirmationMessage;
          return confirmationMessage;
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      return () =>
        window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isUnsafeTabClose]);
  };

  useConfirmTabClose(showCloseConfirmation);

  const handleClose = () => {
    setShowCloseConfirmation(false);
  };

  const handleConfirmClose = () => {
    window.close(); // Ferme le navigateur
  };

  if (platform.os?.family === 'iOS') {
    const id = `${platform.description}${platform.ua}${platform.version}`;
    console.log(id, md5(id));
    LogRocket.init('f34iwc/printyourfriends');
    LogRocket.identify(md5(id), {
      platformVersion: platform.version,
      platformUA: platform.ua,
    });
  }
  return (
    <GlobalProvider>
      <div className="App">
        <RoutesPrintYourFriends />
        <ToastContainer />
        <Modal show={showCloseConfirmation} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation de fermeture</Modal.Title>
          </Modal.Header>
          <Modal.Body>Êtes-vous sûr de vouloir quitter cette page ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="primary" onClick={handleConfirmClose}>
              Fermer le navigateur
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </GlobalProvider>
  );
}

export default App;
