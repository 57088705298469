import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Donut } from 'react-svg-donuts';
import ConfirmationModal from '../../components/confirmation-modal/confirmation-modal';
import Layout from '../../components/layout/layout';
import LoadingModal from '../../components/loading-modal/loading-modal';
import OffCanvas from '../../components/off-canvas/off-canvas';
import { GlobalContext } from '../../context/globalState';
import { getEvent, uploadImage } from '../../services/event';
import './loading.scss';

function Loading() {
  const [counter, setCounter] = React.useState(10);
  const [rest, setRest] = React.useState(-10000);
  const [isOpenCanvasLoading, setIsOpenCanvasLoading] = React.useState(true);
  const [confirmed, setConfirmed] = React.useState(false);
  const [errorApi, setErrorApi] = React.useState(false);
  const [isOpenCanvasConfirmation, setIsOpenCanvasConfirmation] =
    React.useState(false);
  const [intervalData, setIntervalData] = React.useState();
  const [tempPayload, setTempPayload] = React.useState();
  const [event, setEvent] = React.useState();
  const [fillPercent, setFillPercent] = React.useState();
  const [blocked, setBlocked] = React.useState(false);
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { info_events, setDataEvents, quantity } = useContext(GlobalContext);
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    console.log('istate', state);

    // state
    if (state && state.isConfirmed) {
      setConfirmed(true);
      setIsOpenCanvasLoading(false);
      setIsOpenCanvasConfirmation(true);
    } else if (state && state.payload) {
      setConfirmed(false);
      setIsOpenCanvasLoading(true);
      setIsOpenCanvasConfirmation(false);
      setTempPayload(state.payload);
      print(state.payload);
    }
    // info
    if (info_events.id || info_events.nom_evenement) {
      const {
        nombre_maximum_tirage,
        current_tirage_number,
        bloquer_si_max_atteint,
      } = info_events;
      const fp = Math.round(
        (parseInt(current_tirage_number, 10) * 100) /
          parseInt(nombre_maximum_tirage, 10)
      );
      const r =
        parseInt(nombre_maximum_tirage, 10) - parseInt(current_tirage_number);
      setRest(r > -1 ? r : 0);
      setFillPercent(fp);
      setEvent(info_events);
      setBlocked(bloquer_si_max_atteint && r < 1);
    } else if (state && state.slug) {
      setConfirmed(true);
      setIsOpenCanvasLoading(false);
      setIsOpenCanvasConfirmation(true);
      loadEvent(state.slug);
    }
    if (state && state.clearCounter) {
      setCounter(1);
    }
    return () => {
      if (intervalData) {
        clearInterval(intervalData);
        setIntervalData(null);
      }
    };
  }, [info_events, state]);

  React.useEffect(() => {
    let interval;
    if (counter > 0) {
      interval = setInterval(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
      setIntervalData(interval);
    }
    const load = async () => {
      navigate(pathname, { replace: true, state: { slug: info_events.slug } });
      console.log('time stoped');
      if (progress === 100) {
        await loadEvent(info_events.slug);
        setConfirmed(true);
        setIsOpenCanvasLoading(false);
        setIsOpenCanvasConfirmation(true);
        clearInterval(interval);
        setProgress(0);
      }
    };

    if (progress === 100) {
      if (info_events && info_events.slug) {
        load();
        // navigate('/confirmation');
      }
    }
    return () => {
      clearInterval(interval);
      setIntervalData(null);
    };
  }, [info_events, progress]);

  const renderProgress = (progress) => <strong>{parseInt(rest)}</strong>;

  const print = async (payload) => {
    if (!payload) {
      return;
    }
    setErrorApi(false);
    const updateProgress = (progress) => {
      setProgress(progress);
    };

    let isSimulationRunning = true;
    let isCancellationRequested = false;
    const connectionType = navigator?.connection?.effectiveType;
    console.log('concetion type', connectionType);
    let timeoutDuration =
      connectionType === '3g' || connectionType === '2g' ? 150 : 60.77;
    try {
      const simulationPromise = new Promise(async (resolve, reject) => {
        const simulateProgress = async () => {
          for (let progress = 0; progress < 100; progress++) {
            // Vérifiez si l'annulation est demandée
            if (isCancellationRequested) {
              reject('Simulation cancelled');
              return;
            }

            updateProgress(progress);
            await new Promise((resolve) =>
              setTimeout(resolve, timeoutDuration)
            );
          }
          resolve();
        };

        simulateProgress();
      });

      console.log('payload', payload);
      await uploadImage(payload, quantity)
        .then((res) => {
          setProgress(100);
          isCancellationRequested = true;
          return simulationPromise;
        })
        .then(() => {
          if (window.location.pathname === '/loading') {
            //  updateEvent(info_events.id, quantity);
            console.log('Image téléchargée : redirection');
          }
        })
        .catch((error) => {
          if (error === 'Simulation cancelled') {
            console.log('La simulation a été annulée');
          } else {
            console.log('Erreur :', error);
            setErrorApi(true);
            isCancellationRequested = true;
            setProgress(0);
            if (error.code === 'ECONNABORTED') {
              console.log('Request timed out');
            } else {
              console.log(error.message);
            }
            // Gérez les autres erreurs ici
          }
        });
    } catch (error) {
      console.log('Erreur :', error);
      if (error.code === 'ECONNABORTED') {
        console.log('Request timed out 2');
      } else {
        console.log(error.message);
      }
      // Gérez les erreurs ici
    }
  };

  /* const print2 = async (payload) => {
    if (!payload) {
      return;
    }
    await uploadImage(payload)
      .then((res) => {
        // loadEvent(info_events.slug)
        // here should clear
        if (window.location.pathname === '/loading') {
          console.log('image uploaded : navigating');
          // navigate(pathname, {replace: true, state: {slug: info_events.slug}})
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };*/

  const loadEvent = (slug) => {
    getEvent(slug)
      .then((items) => {
        setDataEvents(items?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const resendImage = () => {
    setProgress(0);
    print(tempPayload);
  };

  return (
    <Layout>
      {rest > -10000 &&
        (!confirmed ? (
          <>
            <h2 className="h2-title mb-3">C'est parti pour l'impression ! 🖨</h2>

            <p className="sub-title">
              {!errorApi
                ? 'Retrouve dans quelques secondes ta photo dans l’imprimante'
                : "Il semble y avoir eu un problème lors de l'envoi des photos. Veuillez réessayer l'envoi des photos pour que tout fonctionne correctement. Si le problème persiste, n'hésitez pas à nous contacter pour obtenir de l'aide."}
            </p>

            <div className="loading">
              <p className="loading__heading">IMPRESSION EN COURS</p>
              <p className="loading__text">{progress} % </p>
              Veuillez attendre 100% <br></br>
              avant de quitter la page, <br></br>
              merci
            </div>
          </>
        ) : (
          <>
            <div className="loading__logo mb-4">
              <img alt={event?.nom_evenement} src={event?.logo_client} />
            </div>
            {/*   <h2 className="h2-title">
              Ta photo est disponible dans l'imprimante 🙃
        </h2>*/}
            <div className="mt-2">
              <Donut progress={fillPercent} onRender={renderProgress} />
            </div>
            {rest ? (
              <>
                <p className="tirage text-center">
                  Il reste {rest} {rest === 1 ? 'tirage' : 'tirages'} sur{' '}
                  {event?.nombre_maximum_tirage}.
                </p>
                <p className="tirage text-center">
                  {info_events?.message_chargement}
                </p>
              </>
            ) : (
              <p className="tirage text-center">
                Il ne reste plus de tirage disponible
              </p>
            )}
          </>
        ))}

      {errorApi && (
        <OffCanvas
          isOpen={isOpenCanvasLoading}
          props={{
            placement: 'bottom',
            backdrop: false,
          }}
          content={
            <LoadingModal
              title={event?.message_chargement}
              //  onClickEmail={() => {}}
              isError={errorApi}
              resend={resendImage}
            />
          }
        />
      )}

      {isOpenCanvasConfirmation && (
        <ConfirmationModal
          disabled={blocked}
          title="L’aventure n’est pas finie !"
        />
      )}
      {/*     <OffCanvas
        isOpen={isOpenCanvasConfirmation}
        props={{
          placement: 'bottom',
          backdrop: false,
        }}
        content={
          <ConfirmationModal
            disabled={blocked}
            title="L’aventure n’est pas finie !"
          />
        }
      />*/}
    </Layout>
  );
}

export default Loading;
