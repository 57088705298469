export default function appReducer(state, action) {
  switch (action.type) {
    case 'SET_DATA_EVENEMENTS':
      return {
        ...state,
        info_events: action.payload,
      };

    case 'SET_EMAIL_USER':
      return {
        ...state,
        email: action.payload,
      };

    case 'SET_QUANTITY':
      return {
        ...state,
        quantity: action.payload,
      };

    case 'SET_BASE_IMAGES':
      return {
        ...state,
        base64Images: action.payload,
      };

    case 'RESTART_DATA':
      return {
        ...state,
        info_events: {},
        email: null,
        base64Images: '',
      };

    default:
      return state;
  }
}
