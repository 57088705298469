import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

function OffCanvas({props, content, title, isOpen}) {
  React.useEffect(() => {
    setShow(isOpen);
  }, [isOpen])
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        {title && <Offcanvas.Header>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>}
        <Offcanvas.Body>
          {content}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvas;