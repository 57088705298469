import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../context/globalState';
function QuantityModal({ title, tirage, launchPrint, maxQuantity }) {
  const { setQuantity } = useContext(GlobalContext);
  const [quantity, updateQuantity] = useState(maxQuantity > 0 ? 1 : 0);

  const handleIncrement = () => {
    if (quantity < maxQuantity) {
      setQuantity(quantity + 1);
      updateQuantity(quantity + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      updateQuantity(quantity - 1);
    }
  };

  const clickPrint = () => {
    launchPrint(quantity);
  };

  return (
    <div>
      <div className="print-quantity">
        <p>{title}</p>
        <div className="print-quantity__input-parent">
          <div className="print-quantity__input">
            <button
              className="btn-print btn-print--decrease"
              onClick={handleDecrement}
              disabled={quantity === 1 || quantity === 0}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
              >
                <rect
                  class="btn-print__bg"
                  x="0.5"
                  width="40"
                  height="40"
                  rx="20"
                />
                <rect
                  class="btn-print__label"
                  x="12.5"
                  y="20"
                  width="16"
                  height="2"
                  rx="0.3"
                />
              </svg>
            </button>
            <span className="print-input">{quantity}</span>
            <button
              className="btn-print btn-print--increase"
              onClick={handleIncrement}
              disabled={quantity === maxQuantity}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
              >
                <rect
                  class="btn-print__bg"
                  x="0.5"
                  width="40"
                  height="40"
                  rx="20"
                />
                <rect
                  class="btn-print__label"
                  x="12.5"
                  y="20"
                  width="16"
                  height="2"
                  rx="0.3"
                />
                <rect
                  class="btn-print__label"
                  x="19.5"
                  y="29"
                  width="16"
                  height="2"
                  rx="0.3"
                  transform="rotate(-90 19.5 29)"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="print-quantity__desc">{tirage}</div>
        <button
          className="btn btn-pink"
          onClick={clickPrint}
          disabled={maxQuantity === 0}
        >
          Lancer l'impression
        </button>
      </div>
    </div>
  );
}

export default QuantityModal;
