/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import OffCanvas from '../../components/off-canvas/off-canvas';
import Photos from '../../components/photos/photos';
import PrintModal from '../../components/print-modal/print-modal';
import { GlobalContext } from '../../context/globalState';
import { getEvent } from '../../services/event';
import './home.scss';

function Home() {
  const [isTakePhoto, setTakePhoto] = React.useState(false);
  //  const [hasCamera, setHasCamera] = React.useState(true);
  const [permisionGranted, setPermissionGranted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [event, setEvent] = React.useState({});
  const [hasError, setError] = React.useState(false);
  const [isFull, setIsFull] = React.useState(false);
  const [isOffCanvasOpen, setIsOffCanvasOpen] = React.useState(false);
  const homeRef = React.useRef(null);
  // const [payload, setPayload] = React.useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const {
    setDataEvents,
    setBase64Image,
    info_events: {
      slug,
      nombre_maximum_tirage,
      message_chargement,

      current_tirage_number,
    },
  } = React.useContext(GlobalContext);

  const [loadingSimulate, setLoadingSimulate] = React.useState(true);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoadingSimulate(false);
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  React.useEffect(() => {
    let mounted = true;
    setLoading(true);
    setError(false);
    getEvent(params.event)
      .then((items) => {
        if (mounted) {
          setEvent(items?.data);
          setDataEvents(items?.data);
          if (
            parseInt(items.data.current_tirage_number, 10) >=
            parseInt(items.data.nombre_maximum_tirage, 10)
          ) {
            setIsFull(true);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);

        if (err?.response?.data?.error?.message === 'not_valid') {
          setEvent(err?.response?.data?.error?.details?.zimage);
        } else {
          setError(true);
        }
      });

    return () => (mounted = false);
  }, [params.event]);

  const askPermisionCamera = async () => {
    if (permisionGranted) {
      setTakePhoto(true);
    } else {
      setPermissionGranted(true);
      setTakePhoto(true);
      /*if (navigator && navigator.mediaDevices) {
        await navigator.mediaDevices.enumerateDevices();

        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(() => {
            setPermissionGranted(true);
            setTakePhoto(true);
          })
          .catch((e) => {
            console.error(e);
            toast.warning(
              'Vous devez autoriser votre caméra à utiliser cette application'
            );
          });
      }*/

      /*if (!navigator?.mediaDevices && !navigator?.mediaDevices?.getUserMedia) {
        navigator.userMedia =
          navigator.mozGetUserMedia || navigator.getUserMedia;
        if (!navigator.userMedia) {
          toast.warning(
            'Vous devez autoriser votre caméra à utiliser cette application'
          );

          return;
        }
        navigator.userMedia(
          {
            video: true,
          },
          () => {
            setPermissionGranted(true);
            setTakePhoto(true);
          },
          () => {
            toast.warning(
              'Vous devez autoriser votre caméra à utiliser cette application'
            );
          }
        );
        return;
      }*/
    }
  };
  const resizeAndCompressImage = (
    base64String,
    targetWidth,
    targetHeight,
    quality
  ) => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.fillRect(50, 50, 100, 100);
        // Maintaining the aspect ratio while resizing
        const aspectRatio = img.width / img.height;
        let newWidth, newHeight;

        if (targetWidth / targetHeight > aspectRatio) {
          newWidth = targetHeight * aspectRatio;
          newHeight = targetHeight;
        } else {
          newWidth = targetWidth;
          newHeight = targetWidth / aspectRatio;
        }

        // Set canvas dimensions to the new size
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Draw the image on the canvas while maintaining the aspect ratio
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Convert the canvas content to a Blob with specified quality
        canvas.toBlob(
          (blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const resizedBase64 = reader.result;
              resolve(resizedBase64);
            };
            reader.readAsDataURL(blob);
          },
          'image/jpeg',
          quality // specify the quality (0 to 1, 1 being the best)
        );
      };

      img.onerror = () => {
        reject(new Error('Failed to load the image.'));
      };

      img.src = base64String;
    });
  };

  const onSuccess = async ({ image, payload: pl }) => {
    console.log('On Success fired!');
    setLoading(true);
    setBase64Image(image);
    // navigate('/loading', { replace: true, state: { payload: pl, slug } });
    // setPayload(pl);
    // setIsOffCanvasOpen(true) #v1 hack
    // remove this in v1
    // print(pl);
    resizeAndCompressImage(pl.print, 1240, 1844, 0.8)
      .then((resizedBase64) => {
        pl.print = resizedBase64;
        console.log('res');
        navigate('/loading', { replace: true, state: { payload: pl, slug } });
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <div className={!isTakePhoto ? 'home' : 'home-camera'} ref={homeRef}>
      <div className="container">
        {!isTakePhoto ? (
          <div className="home__wrapper">
            <div className="logo">
              {!loading ? (
                <>
                  <a
                    href="https://printyourfriends.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Print your friends"
                      src="/assets/images/logo-white.png"
                      width={100}
                      height={100}
                    />
                  </a>
                </>
              ) : (
                <Skeleton count={1} />
              )}
            </div>

            <div className="logo__free">
              {!loading ? (
                !hasError && <img alt="Freenow" src={event?.logo_client} />
              ) : (
                <Skeleton count={1} />
              )}
            </div>
            {!loading && isFull && (
              <div className="home__content">
                <h1 className="h1-title home__title">
                  Vous avez fait chauffer l'imprimante 🔥
                </h1>
                <p className="home__desc">
                  Les {nombre_maximum_tirage} tirages ont été utilisés.
                  {!event.bloquer_si_max_atteint ? (
                    <span>
                      &nbsp;(parfois il y a des tirages en + dans la bobine,
                      tente ta chance mais on ne te garantit rien ! 🤞)
                    </span>
                  ) : (
                    ''
                  )}
                </p>
              </div>
            )}

            {!loading && hasError && (
              <div className="home__content">
                <h1 className="h1-title home__title">
                  Désolée, l'URL renseignée correspond à aucun évènement
                </h1>
              </div>
            )}

            {!hasError && (
              <div className="btn-photo">
                <button
                  className="btn btn-pink btn__photo"
                  onClick={askPermisionCamera}
                  disabled={
                    hasError ||
                    loading ||
                    (isFull && event?.bloquer_si_max_atteint)
                  }
                >
                  Prendre une photo 📸
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <Photos
              loading={loading}
              cadrePhotos={event?.cadre_photo}
              idEvent={event?.id}
              folderId={event?.imprimante?.dossier_id_google_drive}
              isSaveGalerie={event?.isSaveGalerie}
              nombre_exemplaires={event.nombre_exemplaires}
              nombre_exemplaires_reste={event.nombre_exemplaires_reste}
              nombre_maximum_tirage={nombre_maximum_tirage}
              current_tirage_number={current_tirage_number}
              loadingSimulate={loadingSimulate}
              dossier_galerie_id_google_drive={
                event?.dossier_galerie_id_google_drive
              }
              onSuccess={onSuccess}
              homeRef={homeRef}
            />
          </>
        )}
      </div>
      <OffCanvas
        isOpen={isOffCanvasOpen}
        props={{
          placement: 'bottom',
          backdrop: false,
        }}
        title="Quelle quantité de photo souhaitez-vous imprimer ?"
        content={<PrintModal onSuccess={() => {}} loading={loading} />}
      />
    </div>
  );
}

export default Home;
