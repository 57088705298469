import axios from 'axios';

export async function getEvent(link) {
  return await axios.get(
    `${process.env.REACT_APP_REST_API_LOCATION}/get-event/${link}`
  );
}
// helper function: generate a new file from base64 String
const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
};

export async function uploadImage(payload, quantity) {
  const formData = new FormData();

  const data = {
    fileName: payload.fileName,
    folder_id: payload.folder_id,
    image: payload.print,
    idEvent: payload.idEvent,
    quantity: quantity,
    isSaveGalerie: payload.isSaveGalerie,
    dossier_galerie_id_google_drive: payload.dossier_galerie_id_google_drive,
  };

  formData.append('file.print', dataURLtoFile(payload.print));
  formData.append('data', JSON.stringify(data));

  return await axios({
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `${process.env.REACT_APP_REST_API_LOCATION}/upload-drive
    `,
    data: formData,
  });
}

export const updateEvent = async (id, quantity) => {
  return await axios.put(
    `${process.env.REACT_APP_REST_API_LOCATION}/update-event/${id}`,
    { quantity: quantity }
  );
};

export async function sendImageEmail(payload) {
  const formData = new FormData();
  const { email, image, eventId } = payload;
  const data = {
    email,
    image,
    eventId,
  };

  formData.append('file', dataURLtoFile(image));
  formData.append('data', JSON.stringify(data));

  return await axios({
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `${process.env.REACT_APP_REST_API_LOCATION}/send-email
    `,
    data: formData,
  });
}
